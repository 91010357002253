import React, {useEffect, useState} from "react"
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import useDebounce from "../../Shared/Hooks";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useDebounceV2 from "../../Hooks/useDebounceV2";

const Comment = () => {
    const {appointment_id} = useParams()
    const [comments, setComments] = useState(undefined)
    const debouncedSaveComments = useDebounceV2((comments) => MedicalEventStore.saveComments(comments, appointment_id), 1000)

    useEffect(() => {
        MedicalEventStore.getComment(appointment_id).then((comments) => {
            setComments(comments)
        }).catch(() => {
            setComments("")
            toast.error("Impossible de charger le commentaire")
        })
    }, []);

    const saveComment = (comments) => {
        setComments(comments)
        debouncedSaveComments(comments)
    }


    return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
        <h6 className="center" style={{margin: 0}}>Commentaire</h6>
        <SmartTextArea value={comments?.comment ?? ""}
                       onChange={(e) => saveComment({...comments, comment: e.target.value})}
                       style={{height: 172, resize: "none"}}
        />
        <h6 className="center" style={{margin: 0}}>Commentaire pour ophtalmologiste</h6>
        <SmartTextArea value={comments?.comment_for_ophtalmologist ?? ""}
                       onChange={(e) => saveComment({...comments, comment_for_ophtalmologist: e.target.value})}
                       style={{height: 172, resize: "none"}}
        />
    </div>
}

export default Comment