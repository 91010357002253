import React, {Component} from "react";

export default class Validator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputText: "",
            childrenWithProps: React.Children.map(this.props.children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, Object.assign({}, child.props, {
                        className: child.props.className ? child.props.className + " modal-trigger" : "modal-trigger",
                        href: `#validation-modal-${this.props.id}`
                    }));
                }
                return child;
            })
        }
    }

    componentDidMount() {
        var modal = document.getElementById(`validation-modal-${this.props.id}`);
        const originalParent = modal.parentNode;

        // Need to bind the modal to the body to prevent cases where the modal is displayed inside a container with overflow hidden
        const bindModalToBody = (modal) => document.body.appendChild(modal);
        const rebindModalToOriginalParent = (modal) => originalParent?.appendChild(modal);

        // Need to bind the validation function to the button since rebinding the modal will remove the event listener
        const bindValidationFunc = (modal) => {
            const instance = M.Modal.getInstance(modal);
            const validationButton = document.getElementById(`validation-btn-${this.props.id}`);
            validationButton.onclick = () => {
                this.props.onValidation(this.props.input ? this.state.inputText : undefined)
                this.setState({inputText: ""})
                instance.close();
            }
        }

        // Need to bind the input to the state
        const bindInputFunc = () => {
            const input = document.getElementById(`validation-input-${this.props.id}`);
            if(!input) return;
            input.onchange = (e) => this.setState({inputText: e.target.value})
        }

        M.Modal.init(modal, {
            onOpenStart: bindModalToBody,
            onOpenEnd: (modal) => {
                bindValidationFunc(modal);
                bindInputFunc();
            },
            onCloseStart: rebindModalToOriginalParent
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.children !== this.props.children) {
            const newChildren = React.Children.map(this.props.children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, Object.assign({}, child.props, {
                        className: child.props.className ? child.props.className + " modal-trigger" : "modal-trigger",
                        href: `#validation-modal-${this.props.id}`
                    }));
                }
                return child
            })
            this.setState({childrenWithProps: newChildren})
        }
    }

    componentWillUnmount() {
        try {
            const elems = document.getElementById(`validation-modal-${this.props.id}`);
            M.Modal.getInstance(elems).destroy();
        } catch (e) {
            console.log(e)
        }
    }

    renderInput = () => {
        if (!this.props.input) return null
        return <div className="input-field outlined">
            <input id={`validation-input-${this.props.id}`} type="text" className="validate"
                   placeholder={this.props.placeholder}
                   onChange={(e) => this.setState({inputText: e.target.value})}/>
            {/*<label htmlFor={`validation-input-${this.props.id}`}>{this.props.input}</label>*/}
        </div>
    }

    render() {
        return (
            <>
                {this.state.childrenWithProps}
                {/*Modal Structure*/}
                <div id={`validation-modal-${this.props.id}`} className="modal" style={{fontSize: "1rem"}}>
                    <div className="modal-content">
                        <h4 className={`center-align black-text`}>{this.props.text}</h4>
                        <p className={`center-align ${this.props.warning ? "red-text" : "black-text "}`}
                           style={{whiteSpace: "pre-wrap", fontWeight: this.props.warning ? "bold" : "normal"}}
                        >
                            {this.props.detail}
                        </p>
                        {this.renderInput()}
                        <div className="row" style={{margin: 10}}>
                            <div className={"col s4 offset-s2 center"}>
                                <a id={`validation-btn-${this.props.id}`}
                                   className={`modal-close btn z-depth-0 white-text ${this.props.warning ? "red darken-2" : this.props.validationColor}`}
                                   style={{fontSize: "1rem"}}
                                   onClick={() => {
                                       this.props.onValidation(this.props.input ? this.state.inputText : undefined)
                                       this.setState({inputText: ""})
                                   }}
                                   data-cy="OnValidationButton">
                                    {this.props.validationIcon !== "" ? (
                                        <i className="material-icons left">{this.props.validationIcon}</i>) : (<div/>)}
                                    {this.props.validationText}
                                </a>
                            </div>
                            <div className={"col s4 center"}>
                                <a className={`modal-close btn z-depth-0 white-text ${this.props.abortColor}`}
                                   style={{fontSize: "1rem"}}>
                                    {this.props.abortIcon !== "" ? (
                                        <i className="material-icons left">{this.props.abortIcon}</i>) : (<div/>)}
                                    {this.props.abortText}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Validator.defaultProps = {
    text: "Voulez-vous vraiment effectuer cette opération ?",
    detail: "",
    validationText: "Valider",
    abortText: "Annuler",
    validationColor: "green",
    abortColor: "red",
    validationIcon: "",
    abortIcon: "",
    id: "no_id",
    placeholder: "",
    warning: false
}
